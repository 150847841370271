<template>
    <div class="wrapper">
        <Head v-if="meta.show">
            <title>{{ generateMetaTitle }}</title>
            <meta
                name="description"
                content="unique IndyCar future racing concept design paintings from designer Matus Prochaczka under label WEKOWORKS. With focus on 2035 look of IndyCar series with drivers Scott Dixon, Helio Castroneves, Will Power, Ryan Hunter-Reay, James Davison, Simon Pagenaud and more..."
            />
        </Head>
        <div data-aos="fade-right">
            <router-link :to="{ name: 'design' }">
                <picture>
                    <source
                        srcset="/img/home/design-bg.webp"
                        type="image/webp"
                    />
                    <source
                        srcset="/img/home/design-bg.jpg"
                        type="image/jpeg"
                    />
                    <img
                        src="/img/home/design-bg.jpg"
                        alt="Design"
                        class="img-background"
                    />
                </picture>
                <picture>
                    <source srcset="/img/home/design.webp" type="image/webp" />
                    <source srcset="/img/home/design.png" type="image/png" />
                    <img
                        src="/img/home/design.png"
                        alt="Design"
                        class="img-overlay"
                    />
                </picture>
                <span>Wekoworks</span>
                <h2 class="title-font">Design</h2>
            </router-link>
        </div>
        <div data-aos="fade-left">
            <router-link :to="{ name: 'paintings' }">
                <picture>
                    <source
                        srcset="/img/home/paintings-bg.webp"
                        type="image/webp"
                    />
                    <source
                        srcset="/img/home/paintings-bg.jpg"
                        type="image/jpeg"
                    />
                    <img
                        src="/img/home/paintings-bg.jpg"
                        alt="Paintings"
                        class="img-background"
                    />
                </picture>
                <picture>
                    <source
                        srcset="/img/home/paintings.webp"
                        type="image/webp"
                    />
                    <source
                        srcset="/img/home/paintings.png"
                        type="image/jpeg"
                    />
                    <img
                        src="/img/home/paintings.png"
                        alt="Paintings"
                        class="img-overlay"
                    />
                </picture>
                <span>Wekoworks</span>
                <h2 class="title-font">Paintings</h2>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            meta: { show: true, title: "Indycar racing paintings" },
        };
    },
    computed: {
        ...mapGetters(["getAppName", "getSeparator"]),
        generateMetaTitle() {
            return this.meta.title + this.getSeparator + this.getAppName;
        },
    },
    deactivated() {
        this.meta.show = false;
    },
    activated() {
        this.meta.show = true;
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/sass/variables.scss";

.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    @media (min-width: 768px) {
        display: flex;
    }
    & > div {
        height: 50%;
        @media (min-width: 768px) {
            width: 50%;
            height: 100%;
        }
    }
}
a {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: $text-color;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 9vw 5vw;

    &:hover {
        color: $text-color;
    }
    @media (hover: hover) {
        &:hover {
            color: $primary-color;
            .img-background {
                transform: scale(1.1);
            }
            .img-overlay {
                transform: scale(1.1);
            }
        }
    }
}

img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.img-background {
    transition: transform 5s;
}

.img-overlay {
    transition: transform 2s;
}

h2 {
    position: relative;
    font-size: calc(1rem + 5vw);
    line-height: 1;
    color: inherit;
    z-index: 3;
    transition: color 0.3s;
}

span {
    display: block;
    position: relative;
    z-index: 3;
    font-size: calc(1rem + 1.7vw);
    color: inherit;
    text-transform: uppercase;
    line-height: 1;
    transition: color 0.3s;
}

@media (max-width: 767px) {
    [data-aos="fade-right"] {
        transform: translate3d(0, -100px, 0);
    }
    [data-aos="fade-left"] {
        transform: translate3d(0, 100px, 0);
    }
}
</style>
