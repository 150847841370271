import { render } from "./BaseButton.vue?vue&type=template&id=0b8c96ba"
import script from "./BaseButton.vue?vue&type=script&lang=js"
export * from "./BaseButton.vue?vue&type=script&lang=js"

import "./BaseButton.vue?vue&type=style&index=0&id=0b8c96ba&lang=scss"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "0b8c96ba"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('0b8c96ba', script)) {
    api.reload('0b8c96ba', script)
  }
  
  module.hot.accept("./BaseButton.vue?vue&type=template&id=0b8c96ba", () => {
    api.rerender('0b8c96ba', render)
  })

}

script.__file = "resources/js/components/UI/BaseButton.vue"

export default script