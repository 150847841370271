import { render } from "./ImgLazy.vue?vue&type=template&id=83c57b38&scoped=true"
import script from "./ImgLazy.vue?vue&type=script&lang=js"
export * from "./ImgLazy.vue?vue&type=script&lang=js"

import "./ImgLazy.vue?vue&type=style&index=0&id=83c57b38&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-83c57b38"
/* hot reload */
if (module.hot) {
  script.__hmrId = "83c57b38"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('83c57b38', script)) {
    api.reload('83c57b38', script)
  }
  
  module.hot.accept("./ImgLazy.vue?vue&type=template&id=83c57b38&scoped=true", () => {
    api.rerender('83c57b38', render)
  })

}

script.__file = "resources/js/components/imglazy/ImgLazy.vue"

export default script