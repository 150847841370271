import { render } from "./TheHeader.vue?vue&type=template&id=12a31364&scoped=true"
import script from "./TheHeader.vue?vue&type=script&lang=js"
export * from "./TheHeader.vue?vue&type=script&lang=js"

import "./TheHeader.vue?vue&type=style&index=0&id=12a31364&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-12a31364"
/* hot reload */
if (module.hot) {
  script.__hmrId = "12a31364"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('12a31364', script)) {
    api.reload('12a31364', script)
  }
  
  module.hot.accept("./TheHeader.vue?vue&type=template&id=12a31364&scoped=true", () => {
    api.rerender('12a31364', render)
  })

}

script.__file = "resources/js/components/layout/TheHeader.vue"

export default script