import { render } from "./Loader.vue?vue&type=template&id=3fc46753&scoped=true"
const script = {}

import "./Loader.vue?vue&type=style&index=0&id=3fc46753&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-3fc46753"
/* hot reload */
if (module.hot) {
  script.__hmrId = "3fc46753"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('3fc46753', script)) {
    api.reload('3fc46753', script)
  }
  
  module.hot.accept("./Loader.vue?vue&type=template&id=3fc46753&scoped=true", () => {
    api.rerender('3fc46753', render)
  })

}

script.__file = "resources/js/components/UI/Loader.vue"

export default script