import { render } from "./TheFooter.vue?vue&type=template&id=1d1ea11c&scoped=true"
import script from "./TheFooter.vue?vue&type=script&lang=js"
export * from "./TheFooter.vue?vue&type=script&lang=js"

import "./TheFooter.vue?vue&type=style&index=0&id=1d1ea11c&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-1d1ea11c"
/* hot reload */
if (module.hot) {
  script.__hmrId = "1d1ea11c"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('1d1ea11c', script)) {
    api.reload('1d1ea11c', script)
  }
  
  module.hot.accept("./TheFooter.vue?vue&type=template&id=1d1ea11c&scoped=true", () => {
    api.rerender('1d1ea11c', render)
  })

}

script.__file = "resources/js/components/layout/TheFooter.vue"

export default script