import { render } from "./Content.vue?vue&type=template&id=7f310169&scoped=true"
const script = {}

import "./Content.vue?vue&type=style&index=0&id=7f310169&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-7f310169"
/* hot reload */
if (module.hot) {
  script.__hmrId = "7f310169"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('7f310169', script)) {
    api.reload('7f310169', script)
  }
  
  module.hot.accept("./Content.vue?vue&type=template&id=7f310169&scoped=true", () => {
    api.rerender('7f310169', render)
  })

}

script.__file = "resources/js/components/UI/Content.vue"

export default script