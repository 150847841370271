import { render } from "./Home.vue?vue&type=template&id=b3c5cf30&scoped=true"
import script from "./Home.vue?vue&type=script&lang=js"
export * from "./Home.vue?vue&type=script&lang=js"

import "./Home.vue?vue&type=style&index=0&id=b3c5cf30&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-b3c5cf30"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b3c5cf30"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b3c5cf30', script)) {
    api.reload('b3c5cf30', script)
  }
  
  module.hot.accept("./Home.vue?vue&type=template&id=b3c5cf30&scoped=true", () => {
    api.rerender('b3c5cf30', render)
  })

}

script.__file = "resources/js/pages/Home.vue"

export default script