<template>
    <div data-nosnippet>
        <p>
            Use and view this website You agree to store and store cookies on
            target services.
        </p>
        <base-button @click="setCookieAccept" :noWrap="true"
            >I agree</base-button
        >
    </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseButton from "../UI/BaseButton.vue";

export default {
    components: {
        BaseButton,
    },
    methods: {
        ...mapActions(["setCookieAccept"]),
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/sass/variables.scss";

div {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    background: $base-color;
    color: $text-color;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
}
p {
    margin-right: 1rem;
}
</style>
